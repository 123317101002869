//ヘッダをfixedにしていることで生じるアンカーリンクの誤差修正
$(function () {
  $('a[href^=#]').click(function() {
    var target = $(this.hash);
    if (!target.length) return ;
    var targetY = target.offset().top-80;
    $('html,body').animate({scrollTop: targetY}, 800, 'swing');
    window.history.pushState(null, null, this.hash);
    return false;
  });

  $('#close').click(
    function() {
      $('#navigation-2').slideUp(240);
    });

  $('#navigation-2-trigger, #navigation-2').hover(
    function() {
      $('#navigation-2').stop().slideDown(240);
    },
    function() {
      $('#navigation-2').stop().slideUp(240);
    });

  $(window).scroll(function() {
    var now = $(window).scrollTop();
    var end = $(document).height();

    if (now > 480) {
      $('#page-top, #pageSsba').fadeIn(240);
    } else {
      $('#page-top, #pageSsba').fadeOut(240);
    }
  });

  $('#page-top a').hover(
    function() {
      $(this).stop().animate({ opacity : 0.64 }, 240, 'swing');
    },
    function() {
      $(this).stop().animate({ opacity : 1 }, 240, 'swing');
    });

  $('#page-top a').click(function() {
    $('html, body').animate({ scrollTop : 0 }, 800, 'swing');
  });

});
